import { explorerPlugin } from '@graphiql/plugin-explorer';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { GraphiQL } from 'graphiql';
import axios from 'axios';
import { useEffect, useState } from 'react';
import 'graphiql/graphiql.min.css';
import '@graphiql/plugin-explorer/dist/style.css';
import './index.css';
import './App.css';
import { codeUrlOptionPlugin } from './plugins/urlOption';
import { PrettifyIcon, ToolbarButton, Dialog } from '@graphiql/react';

// pass the explorer props here if you want
export default function App() {
  const [url, setUrl] = useState('');
  const [settings, setSettings] = useState({
    shouldPersistHeaders: true,
  });
  useEffect(() => {
    getUrl();
    loadSettingFromLocalStorage();
  }, []);
  /**
   * Set url endpoint.
   */
  async function getUrl() {
    const href = window.location.origin;
    const url = ''
      .concat(window.location.href, '/config')
      .replace('//config', '/config');
    const { data } = await axios.get(url);
    console.log('resp', data);
    setUrl(`${window.location.origin}${data.endpoint}`);
  }
  function loadSettingFromLocalStorage() {
    setSettings({
      shouldPersistHeaders:
        localStorage.getItem('graphiql:shouldPersistHeaders') === 'true' ||
        false,
    });
  }

  const explorer = explorerPlugin({
    explorerIsOpen: true,
    showAttribution: true,
  });
  const urlOption = codeUrlOptionPlugin({
    explorerIsOpen: true,
    showAttribution: true,
  });

  const toolbar = (): any => {
    return (
      <>
        <ToolbarButton label="Prettify query (Shift-Ctrl-P)">
          <PrettifyIcon className="graphiql-toolbar-icon" aria-hidden="true" />
        </ToolbarButton>
      </>
    );
  };

  const dialog = Dialog({});
  const [query, setQuery] = useState(``);
  return (
    <>
      <Dialog open={false}>
        <div className="graphiql-dialog-header">
          <Dialog.Title className="graphiql-dialog-title">
            Short Keys
          </Dialog.Title>
          <Dialog.Close />
        </div>
        <div className="graphiql-dialog-section"></div>
      </Dialog>
      {url.length > 0 && (
        <GraphiQL
          shouldPersistHeaders={settings.shouldPersistHeaders}
          showPersistHeadersSettings={true}
          fetcher={createGraphiQLFetcher({
            url: url,
          })}
          // toolbar={{
          //   additionalContent: toolbar(),
          // }}
          query={query}
          onEditQuery={setQuery}
          plugins={[explorer]}
        />
      )}
      <div className="server-info">Endpoint: {url}</div>
    </>
  );
}
